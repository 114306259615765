import React from "react";
import { Link } from "gatsby";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage, GatsbyImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../../layouts/secondary-landing";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import SEO from "../../../components/seo/seo";
import VideoCard from "../../../components/video-card";
import VimeoVideoFrame from "../../../components/custom-widgets/vimeo-video-frame";
import StretchedLinkCards from "../../../components/card/stretched-link-cards";
import WaFdBankStatesMap from "../../../assets/wafd-bank-9-states-plain-map.svg";
import ServiceStatesLinks from "../../../components/service-states/service-states-links";
import MktoForm from "../../../components/mkto-form/mkto-form";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import showMktoForm from "../../../helpers/showMktoForm";

const SmallBusinessBanking = () => {
  const imgData = useStaticQuery(graphql`
    {
      sbCreditCard: file(relativePath: { eq: "cards/thumbnail-business-credit-card-090424.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      sbChecking: file(relativePath: { eq: "cards/thumbnail-business-checking-102623.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      sbSavings: file(relativePath: { eq: "cards/thumbnail-business-savings-102623.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      lineOfCredit: file(relativePath: { eq: "thumbnail-business-lines-of-credit-102723.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      zelleImage: file(relativePath: { eq: "thumbnail-business-zelle-042224.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      businessTermLoansImage: file(relativePath: { eq: "thumbnail-business-term-loans-102723.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      videoThumbnail1: file(relativePath: { eq: "cards/small-business/thumbnail-northwest-center-050224.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: CONSTRAINED)
        }
      }
      videoThumbnail2: file(relativePath: { eq: "cards/small-business/thumbnail-eakman-construction-050224.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: CONSTRAINED)
        }
      }
      videoThumbnail3: file(relativePath: { eq: "cards/small-business/thumbnail-gencap-logo-102924.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: CONSTRAINED)
        }
      }
    }
  `);
  const breadcrumbData = [
    {
      id: 1,
      url: "/business-banking/small-business",
      title: "Business Banking"
    },
    {
      id: 2,
      active: true,
      title: "Small Business Banking"
    }
  ];

  const SEOData = {
    title: "Banking for Small Business",
    meta: [
      {
        name: "title",
        property: "og:title",
        content: "Banking for Small Business"
      },
      {
        name: "description",
        property: "og:description",
        content:
          "Learn about banking for small business at WaFd Bank. Our small business services include bank accounts, loans, & more. Make the most of your money at WaFd Bank."
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/business-banking/small-business"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/thumb-small-business-banking-02-250.jpg"
      }
    ]
  };

  const bestBankVideoData = {
    title: "Why WaFd Bank is the Best Bank for Small Business",
    showTitle: true,
    vimeoId: "495516934",
    minHeight: "360px",
    class: "m-auto iframe w-100 border-radius-12",
    classTitle: "text-center mb-0"
  };

  const builtForBusinessVideoData = {
    title: "Built for Business",
    showTitle: true,
    vimeoId: "899307145",
    minHeight: "360px",
    class: "m-auto iframe w-100 border-radius-12",
    classTitle: "text-center mb-0"
  };

  const businessProductsData = {
    sectionClass: "bg-white",
    sectionId: "business-products-section",
    cards: [
      {
        id: "sb-credit-card",
        title: "Small Business Credit Card",
        titleClass: "text-success",
        text: "Designed for small business owners, this card offers flexibility and convenience, plus cashback rewards!",
        image: {
          imgVariable: imgData.sbCreditCard.childImageSharp.gatsbyImageData,
          altText: "Businessman looking at credit card making purchase on laptop."
        },
        externalUrl:
          "https://creditcards.wafdbank.com/apply/?store=wafdbank&product=wafd-bank-community-business-cash-back-rewards-visa-credit-card&v_id=null&_st=1699306304820&ga_id=3a775831-c358-4f65-b564-0f8abdf9bf8c",
        button: {
          id: "sb-credit-card-apply-cta",
          containerClass: "mt-auto w-100",
          showIcon: false,
          externalLink: true,
          target: "blank",
          class: "btn-primary btn-block mt-3",
          text: "Apply Now",
          url: "https://creditcards.wafdbank.com/apply/?store=wafdbank&product=wafd-bank-community-business-cash-back-rewards-visa-credit-card&v_id=null&_st=1699306304820&ga_id=3a775831-c358-4f65-b564-0f8abdf9bf8c"
        }
      },
      {
        id: "sb-checking",
        title: "Business Checking",
        titleClass: "text-success",
        text: "Choose the WaFd Bank business checking account to meet your needs. Compare our business accounts to find the right checking account for your business.",
        image: {
          imgVariable: imgData.sbChecking.childImageSharp.gatsbyImageData,
          altText: "Businessman doing online banking on a mobile phone."
        },
        url: "/business-banking/business-checking-account",
        button: {
          id: "sb-checking-open-account-cta",
          containerClass: "mt-auto w-100",
          showIcon: false,
          class: "btn-primary btn-block mt-3",
          text: "Open an Account",
          url: "/business-banking/business-checking-account"
        }
      },
      {
        id: "sb-savings",
        title: "Business Savings",
        titleClass: "text-success",
        text: "Grow your business capital by opening a small business savings account with WaFd Bank.",
        image: {
          imgVariable: imgData.sbSavings.childImageSharp.gatsbyImageData,
          altText: "Businesswoman smiling while using a handheld calculator."
        },
        url: "/business-banking/business-savings-account",
        button: {
          id: "sb-savings-open-account-cta",
          containerClass: "mt-auto w-100",
          showIcon: false,
          class: "btn-primary btn-block mt-3",
          text: "Open an Account",
          url: "/business-banking/business-savings-account"
        }
      }
    ]
  };

  const businessProductsData2 = {
    sectionClass: "bg-white",
    sectionId: "business-products-section-2",
    cards: [
      {
        id: "business-line-of-credit",
        title: "Business Lines of Credit",
        titleClass: "text-success",
        text: "If you need to borrow funds to support cyclical or seasonal cash flow, Business Lines of Credit are often the right solution.",
        image: {
          imgVariable: imgData.lineOfCredit.childImageSharp.gatsbyImageData,
          altText: "Woman holding a digital tablet and looking at potted flowers in a greenhouse"
        },
        url: "/business-banking/near-me",
        button: {
          id: "sb-banker-cta",
          containerClass: "mt-auto w-100",
          showIcon: false,
          class: "btn-primary btn-block mt-3",
          text: "Find a Community Banker",
          url: "/business-banking/near-me"
        }
      },
      {
        id: "use-zelle-for-business",
        title: "Use Zelle<sup>&reg;</sup> for Your Business",
        titleClass: "text-success",
        text: "Easily receive payment from your customers anywhere, anytime in just minutes! Plus, you can use Zelle<sup>&reg;</sup>  to pay other enrolled businesses. Get started in online banking today!",
        image: {
          imgVariable: imgData.zelleImage.childImageSharp.gatsbyImageData,
          altText: "People buying flowers at an outdoor booth with a Zelle accepted here sign."
        },
        url: "/business-banking/online-banking/zelle",
        button: {
          id: "sb-banker-cta",
          containerClass: "mt-auto w-100",
          showIcon: false,
          class: "btn-primary btn-block mt-3",
          text: "Learn More About Zelle<sup>&reg;</sup>",
          url: "/business-banking/online-banking/zelle"
        }
      },
      {
        id: "business-term-loans",
        title: "Business Term Loans",
        titleClass: "text-success",
        text: "Equipment Financing, capital improvements, or finance future expansion, Business Term Loans are the ideal solution.",
        image: {
          imgVariable: imgData.businessTermLoansImage.childImageSharp.gatsbyImageData,
          altText: "Smiling chief standing in auto park and holding tablet"
        },
        url: "/locations",
        button: {
          id: "locations-cta",
          containerClass: "mt-auto w-100",
          showIcon: false,
          class: "btn-primary btn-block mt-3",
          text: "Apply at a Branch",
          url: "/locations"
        }
      }
    ]
  };

  const sbStatePageLinks = {
    stateData: [
      {
        id: "arizona-state-page-link",
        enName: "Arizona",
        enUrl: "/about-us/business-banking/arizona"
      },
      {
        id: "california-state-page-link",
        enName: "California",
        enUrl: "/about-us/business-banking/california"
      },
      {
        id: "idaho-state-page-link",
        enName: "Idaho",
        enUrl: "/about-us/business-banking/idaho"
      },
      {
        id: "nevada-state-page-link",
        enName: "Nevada",
        enUrl: "/about-us/business-banking/nevada"
      },
      {
        id: "new-mexico-state-page-link",
        enName: "New Mexico",
        enUrl: "/about-us/business-banking/new-mexico"
      },
      {
        id: "oregon-state-page-link",
        enName: "Oregon",
        enUrl: "/about-us/business-banking/oregon"
      },
      {
        id: "texas-state-page-link",
        enName: "Texas",
        enUrl: "/about-us/business-banking/texas"
      },
      {
        id: "utah-state-page-link",
        enName: "Utah",
        enUrl: "/about-us/business-banking/utah"
      },
      {
        id: "washington-state-page-link",
        enName: "Washington",
        enUrl: "/about-us/business-banking/washington-state"
      }
    ]
  };

  const videoCardData1 = {
    id: "vimeo-video-modal",
    idSuffix: "1",
    image: {
      imgVariable: imgData.videoThumbnail1.childImageSharp.gatsbyImageData,
      altText: "Northwest Center Video Testimonial."
    },
    bodyContent: (
      <>
        <p>
          <em>
            &ldquo;WaFd was a gamechanger for Northwest Center during the pandemic...all we knew is we had a waitlist so
            long, and we needed to serve these kids here, and WaFd came through.&rdquo;
          </em>
        </p>
        <p className="mb-0">
          <strong>Robyn Brown</strong>
          <br />
          Chief Development Officer, Northwest Center
        </p>
      </>
    ),
    video: {
      id: "northwest-center-video-modal",
      vimeoId: "641324519",
      title: "How WaFd Bank Helped Northwest Center During the Pandemic - Best Bank for Small Business"
    }
  };

  const videoCardData2 = {
    id: "vimeo-video-modal",
    idSuffix: "2",
    image: {
      imgVariable: imgData.videoThumbnail2.childImageSharp.gatsbyImageData,
      altText: "Eakman Construction Video Testimonial."
    },
    bodyContent: (
      <>
        <p>Why Eakman Construction Partners with WaFd Bank - Local Bank for Small Business</p>
        <p className="mb-0">
          <strong>Stacy Eakman</strong>
          <br />
          President, Eakman Construction
        </p>
      </>
    ),
    video: {
      id: "eakman-construction-video-modal",
      vimeoId: "483767208",
      title: "Why Eakman Construction Partners with WaFd Bank"
    }
  };
  const testimonialData3 = {
    id: "review-and-testimonial-3",
    image: {
      imgVariable: imgData.videoThumbnail3.childImageSharp.gatsbyImageData,
      altText: "GenCap Construction Corp., logo."
    },
    bodyContent: (
      <>
        <p>
          <em>
            &ldquo;We could not be happier with our decision to shift our treasury management business to WaFd. The WaFd
            team has been an absolute pleasure to work with. They helped us achieve improved returns on our cash, better
            FDIC protection on deposited funds through cash sweeps, and WaFd provides a more robust client portal for
            our capital management. These benefits are accompanied by WaFd's personal touch and proactive communication.
            WaFd treats us as a valued customer, and they are a valued business partner of GenCap Construction
            Corp.&rdquo;
          </em>
        </p>
        <p className="mb-0">
          <strong>Rob Warnaca</strong>
          <br />
          President, GenCap Construction Corp.
        </p>
      </>
    )
  };

  const TestimonialCard = ({ id, image, bodyContent }) => {
    return (
      <div id={id} className={`col mb-4 mb-lg-0 card bg-transparent border-0`}>
        {/** Image/Header */}
        <GatsbyImage image={image.imgVariable} alt={image.altText} className="card-image-top border-radius-12" />
        {/** Body */}
        <div className="card-body px-0 pb-0 d-flex flex-column align-items-start">{bodyContent}</div>
      </div>
    );
  };

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <Breadcrumb data={breadcrumbData} />
      <MktoForm />
      <section className="container pb-0">
        <h1>Small Business Banking</h1>
      </section>
      <section className="bg-light">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h2>Why WaFd Bank is the Best Bank for Small Business</h2>
              <h4>
                At WaFd Bank we believe in small business, offering products and services designed to help them get
                established and grow. Make the most of your money with our small business banking solutions.
              </h4>
            </div>
            <div className="col-md-6">
              <VimeoVideoFrame {...builtForBusinessVideoData} />
            </div>
          </div>
        </div>
      </section>
      <StretchedLinkCards {...businessProductsData} />
      <section className="bg-light">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mb-3 mb-md-0">
              <h2>Business Services</h2>
              <p>
                Keeping up with day-to-day operations and providing exceptional customer experiences is crucial to long
                term success. WaFd Bank is here to help you streamline your operations, improve your cash flow, and keep
                your customers coming back for more with a variety of business services and solutions.
              </p>
              <Link
                className="btn btn-primary w-100 w-sm-auto"
                to="/business-banking/business-services"
                id="business-services-cta"
              >
                Learn about WaFd Business Services
              </Link>
            </div>
            <div className="col-md-6">
              <VimeoVideoFrame {...bestBankVideoData} />
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-6 mb-3 mb-md-0">
              <StaticImage
                src="../../../images/thumbnail-commercial-banker-112524.jpg"
                alt="Business owners talking to a banking professional about financial solutions."
                placeholder="blurred"
                quality="100"
              />
            </div>
            <div className="col-md-6">
              <h2>Consider Us Your Financial Concierge</h2>
              <p>
                With years of experience banking professional practice and service professionals, we speak your
                language. Count on us for tailored financing solutions, whether you're acquiring or buying into a
                practice, expanding, refinancing, or buying the real estate upon which you operate your business. Let
                WaFd Bank help you get the funds you need for whatever your plans are.
              </p>
              <button
                id="contact-commercial-banker-form-button"
                className="btn btn-primary w-100 w-sm-auto no-min-width"
                onClick={(e) => {
                  e.preventDefault();
                  showMktoForm(1067);
                }}
              >
                Find A Commercial Banker
              </button>
            </div>
          </div>
        </div>
      </section>
      <StretchedLinkCards {...businessProductsData2} />
      <section className="bg-light">
        <div className="container">
          <div className="d-md-flex justify-content-between align-items-center">
            <h2>See why everyone is talking about WaFd Bank</h2>
            <div className="mb-4 mb-md-0">
              <Link id="reviews-cta" className="font-weight-bold text-decoration-none" to="/about-us/reviews">
                Reviews and Testimonials <FontAwesomeIcon icon={["fal", "arrow-right"]} />
              </Link>
            </div>
          </div>
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3">
            <VideoCard {...videoCardData1} />
            <VideoCard {...videoCardData2} />
            <TestimonialCard {...testimonialData3} />
          </div>
        </div>
      </section>
      <section className="container">
        <div className="row">
          <div className="col-md-6 col-lg-3">
            <img className="w-100" src={WaFdBankStatesMap} alt="WaFd Bank States Map." />
          </div>
          <div className="col-md-6 col-lg-9">
            <h2 className="font-weight-semi-bold">WaFd Bank is the Best Bank for Small Business in the Western US</h2>
            <ServiceStatesLinks {...sbStatePageLinks} />
          </div>
        </div>
      </section>
    </SecondaryLanding>
  );
};

export default SmallBusinessBanking;
