import React from "react";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";

import "./video-image.scss";

const VideoImage = ({ image, onClick, id, className, imageClassName }) => (
  <div id={id} className={`d-flex justify-content-center align-items-center ${className}`} onClick={onClick}>
    <GatsbyImage
      image={image.imgVariable}
      alt={image.altText}
      className={`${onClick ? "video-image-hover" : ""} ${imageClassName}`}
    />
    <StaticImage
      src="../../images/icons/play-button.png"
      alt="Play Icon."
      placeholder="blurred"
      quality="100"
      style={{ position: "absolute" }}
    />
  </div>
);

VideoImage.defaultProps = {
  image: {
    imgVariable: "",
    altText: "Image"
  },
  onClick: null,
  id: "video-image",
  className: "",
  imageClassName: ""
};

export default VideoImage;
